import React from "react"

import Page, { Footer, Header } from "@src/components/Page"
import TrustpilotBanner from "@src/components/Trustpilot/TrustpilotBanner"
import TrustpilotWidget from "@src/components/Trustpilot/TrustpilotWidget"
import CentralizedColumn from "@src/components/CentralizedColumn"
import DefaultSeparator from "./_component_default_separator"

import LoremIpsum from "@src/components/LoremIpsum"

const TrustpilotTestPage = () => {
  const title = "Trustpilot Test Page"
  const headerContent = (
    <>
      <DefaultSeparator />
      <CentralizedColumn>
        <LoremIpsum />
      </CentralizedColumn>
      <DefaultSeparator />
      <TrustpilotBanner>
        <TrustpilotWidget />
      </TrustpilotBanner>
    </>
  )

  return (
    <Page
      title={title}
      header={<Header />}
      headerContent={headerContent}
      footer={<Footer />}
    >
      <DefaultSeparator />
      <LoremIpsum />
      <LoremIpsum />
      <DefaultSeparator />
    </Page>
  )
}

export default TrustpilotTestPage
